import React, { useState } from 'react'
import { axiosInstance } from '../../middleware/axiosInstance'
import { useNavigate } from 'react-router-dom'


const Login = ({setIsAuth}) => {
    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")
    const navigate = useNavigate()

    const checkLogin = (e)=>{
        e.preventDefault()
        if (!username || !password) {
            return
        }
        if (username.length > 180 || password.length > 60) {
            return 
        }
        const formData = new FormData(e.target)

        axiosInstance.post('login',formData)
        .then((res)=>{
            if (res?.data?.token) {
                const token = res.data.token
                console.log("asdasd");
                axiosInstance.defaults.headers.common = {
                    'Authorization': 'Bearer ' + token
                };
                setIsAuth(true)
                navigate("/admin/home")
            }
        })
        .catch((err)=>{
            console.error(err)
        })

    }

  return (
    <form onSubmit={checkLogin} id='login-form'>
        <div>
            <label htmlFor="user">Username</label>
            <input type="text" id='user' name='username' value={username.trim()} onChange={(e)=> setUsername(e.target.value)} maxLength={100}/>
        </div>

        <div>
            <label htmlFor="password">Password</label>
            <input type="text" id='password' name='password' maxLength={100} value={password.trim()} onChange={(e)=> setPassword(e.target.value)}/>
        </div>

        <input type="submit" value="SUBMIT" id='submit-btn' />
       
    </form>
  )
}

export default Login