import React from 'react'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../../../middleware/axiosInstance'

const list = [
    "Add new Project",
    "Handle Projects",
    "Add images in Gallery page"
]

const AdminHome = () => {
 
  return (
    <div >
        {list.map((item,index)=>
        <div key={"item_" + index}>
              <Link>{item}</Link>
        </div>
          
    )}
    </div>
  )
}

export default AdminHome