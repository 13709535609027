import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
const images = ["/assets/uploads/images/img1.jpg","/assets/uploads/images/img2.jpg","/assets/uploads/images/img3.jpg","/assets/uploads/images/img4.jpg","/assets/uploads/images/img5.jpg","/assets/uploads/images/img6.jpg","/assets/uploads/images/img7.jpg","/assets/uploads/images/img8.jpg","/assets/uploads/images/img9.jpg","/assets/uploads/images/img10.jpg","/assets/uploads/images/img1.jpg","/assets/uploads/images/img2.jpg","/assets/uploads/images/img3.jpg","/assets/uploads/images/img4.jpg",]

export const GalleryImages = () => {
    const [isShowingImage,setIsShowingImage] = useState(false)
    const [imgSrc,setImgSrc] = useState(null)

    const location = useLocation()

   
    const showImage = useCallback((e,index)=>{
        setIsShowingImage(true)
        setImgSrc(index)
    },[setIsShowingImage,setImgSrc])

    useEffect(()=>{
        if (isShowingImage) {
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
    },[isShowingImage])

  return (
    <div className='gallery__images'>
        {location.state && <h1 className='gallery__images__gallery-name'>Galerie<span>{location.state}</span></h1>}
       { isShowingImage && <div className='black__background'>
            <img src={images[imgSrc]} alt=''/>
            <span role='button' className='btn-exit' onClick={()=>{
                setImgSrc(null)
                setIsShowingImage(false)
            }}>X</span>

            <span role='button' className='btn-right' onClick={()=>{
                 setImgSrc(imgSrc + 1)
                 if (images.length - 1 === imgSrc  ) {
                    setImgSrc(0)
                    return
                }
                
            }}>{">>"}</span>

            <span role='button' className='btn-left' onClick={()=>{
                            setImgSrc(imgSrc - 1)
                            if (0 === imgSrc  ) {
                                setImgSrc(images.length - 1)
                                return
                            }
                        }}>{"<<"}</span>
            </div>}
        <div className='gallery__images__flex'>
        { images.map((image,index)=>
            <figure>
                <img onClick={(e)=>showImage(e,index)} src={image} alt=''/>
            </figure>
        )}
        </div>

        {/* {images[0] && <div className='gallery__images__structure__mobile'>
                <div className='col1 col'>
                {images.map((image,index)=>
                    index >= 0 && index <= 7 && 
                    <img onClick={(e)=>showImage(e,index)} className={`${index === imgSrc && isShowingImage ? "big" : ""}`} src={image} alt=''/>
                )}  
                </div>
                <div className='col2 col'>
                {images.map((image,index)=>
                    index >= 7 && index <= 14 && 
                    <img onClick={(e)=>showImage(e,index)} className={`${index === imgSrc && isShowingImage ? "big" : ""}`} src={image} alt=''/>
                )}  
                </div>
                
        </div>} */}
    </div>
  )
}

