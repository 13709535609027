import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route, useLocation, Outlet, Navigate } from 'react-router-dom';
import  Fallback  from './components/Spinner/Fallback';

import './styles/app.css';

import { Header } from './components/layout/Header/Header';
import { GalleryImages } from './views/Gallery/GalleryImages';
import AdminHome from './views/protected/adminHome/AdminHome';
import Login from './views/Login/Login';
import { axiosInstance } from './middleware/axiosInstance';

const Home = lazy(() => import('./views/Home/Home'));
const Gallery = lazy(() => import('./views/Gallery/Gallery'));
const About = lazy(() => import('./views/About/About'));
const Services = lazy(() => import('./views/Services/Services'));
const Project = lazy(() => import('./views/Project/Project'));
const Corporate = lazy(() => import('./views/Corporate/Corporate'));
const Tarifs = lazy(() => import('./views/Tarifs/Tarifs'));


const colorMap = {
  "/services": "back__orange",
  "/corporate": "back__orange",
  "/tarifs": "back__black",
  "/contact": "back__orange",
  "/a_propos": "back__orange",
  "/galerie": "back__black",
};

/**
 * 
 * App component
 * 
 */
function App() {
  const [isShowingPages, setIsShowingPages] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [headerColor, setHeaderColor] = useState("");
  const { pathname } = useLocation();
  

  useEffect(() => {
    setHeaderColor(colorMap[pathname] || "");
  }, [pathname]);

  const test = ()=>{
    axiosInstance.get("admin/home")
  }

  console.log(headerColor);
  return (
    <div className={`app ${headerColor}`} >

      <Header isShowingPages={isShowingPages} setIsShowingPages={setIsShowingPages} headerColor={headerColor} />
      {/* <div onClick={test} style={{color:"red"}}>provatest</div> */}
      <main className="main">
        <Routes>
          <Route exact path="/" element={<Suspense fallback={<Fallback/>}><Home /></Suspense>} />
          <Route path="/corporate" element={<Suspense fallback={<Fallback/>}><Corporate /></Suspense>} />
          <Route path="/tarifs" element={<Suspense fallback={<Fallback/>}><Tarifs /></Suspense>} />
          <Route path="/services" element={<Suspense fallback={<Fallback/>}><Services /></Suspense>} />
          <Route path="/a_propos" element={<Suspense fallback={<Fallback/>}><About /></Suspense>} />
          <Route path="/galerie" element={<Suspense fallback={<Fallback/>}><Gallery /></Suspense>} />
          <Route path="/galerie/:name" element={<Suspense fallback={<Fallback/>}><GalleryImages /></Suspense>} />
          <Route path="projet/:name" element={<Suspense fallback={<Fallback/>}><Project /></Suspense>} />
          <Route path='/login' element={<Login setIsAuth={setIsAuth} />}/>
        <Route element={<ProtectedRoute isAuth={isAuth} setIsAuth={setIsAuth}/>}>
              <Route path='/admin/home' element={<AdminHome/>}/>
        </Route>

          
        </Routes>
      </main>
    </div>
  );
}

export default App;







const ProtectedRoute = ({isAuth,setIsAuth})=>{

  const logout = ()=>{
    delete axiosInstance.defaults.headers.common['Authorization']
    setIsAuth(false)
  }

  if (isAuth) {
      return (
        <div>
          <p className='logout-tbn' onClick={logout}>Logout</p>
            <Outlet/>
        </div>
        
      )
  }else{
      return <Navigate to="/login"/>
  }
}